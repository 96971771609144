<template>
  <div class="customDateCell">
    <a-button @click.stop="switchHoliday" :type="isHoliday ? 'primary' : ''">
      {{ isHoliday ? '休息日' : '工作日' }}
    </a-button>
  </div>
</template>
<script>
export default {
  props: {
    date: {
      type: Object,
      default: null
    },
    isHoliday: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    switchHoliday() {
      this.$emit('switchHoliday', this.date);
    }
  }
}
</script>
<style>
.customDateCell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>